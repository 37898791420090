import Blockly from 'node-blockly/browser';

const setFieldFromId = (blockId, fieldName, value) => {
  console.log(`setting BLock with ${ fieldName }with the Value${ value}`);

  const block = Blockly.mainWorkspace.getBlockById(blockId);
  block.setFieldValue(value, fieldName);
};

const getFieldFromId = (blockId, fieldName) => {
  const block = Blockly.mainWorkspace.getBlockById(blockId);
  return block.getFieldValue(fieldName);
};


const hashCode = (s) => {
  let hash = 0; let i; let chr;
  if (s.length === 0) return hash;
  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export {setFieldFromId, getFieldFromId, hashCode};


