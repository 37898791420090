import {put, takeEvery} from 'redux-saga/effects';
import {
  WORKING_MODE_SWITCHER_START,
  WORKING_MODE_SWITCHER_SUCCESS,
} from './AppActions';
import {WORKING_MODE_POWER_ANALYSIS} from './workingModeConfigurations';
import {
  POWERANALYSIS_CALCULATE_POWER_MARGIN_START,
} from '../power/PowerAction';

const switchWorkingMode = function* (action) {
  if (action.workingMode === WORKING_MODE_POWER_ANALYSIS) {
    yield put({
      type: POWERANALYSIS_CALCULATE_POWER_MARGIN_START,
    });
  }

  yield put({
    type: WORKING_MODE_SWITCHER_SUCCESS,
    workingMode: action.workingMode,
  });
};

const appSaga = function* () {
  yield takeEvery(WORKING_MODE_SWITCHER_START, switchWorkingMode);
};

export default appSaga;
