import {diffLines} from 'diff';
import {call, select, throttle, put} from 'redux-saga/effects';
import {
  HISTORY_END_ANNOTATION_ARRAY_SUCCESS,
  HISTORY_END_ANNOTATION_SUCCESS,
} from '../history/HistoryAction';

import {getHistory} from '../history/selector';
import {HISTORY_GET_SEQUENCE_START} from './SequencingAction';


const doSequencing = (id, input) => {
  const namedSequence = [];

  for (let i = 0; i < input.length - 1; i++) {
    const output = diffLines(input[i].xml, input[i + 1].xml);
    // console.table(output);
    const parser = new DOMParser();
    const addedField = output.find(item => item.added);
    if (addedField) {
      const xmlDoc = parser.parseFromString(addedField.value, 'text/xml');

      if (xmlDoc.getElementsByTagName('field')[0]) {
        namedSequence.push(
          {
            name: xmlDoc.getElementsByTagName('field')[0].attributes.getNamedItem('name').nodeValue,
            value: xmlDoc.getElementsByTagName('field')[0].innerHTML,
            date: input[i + 1].date,
          });
      }
      else {
        namedSequence.push(
          {
            name: 'error, probably blocking',
            value: output,
            date: input[i + 1].date,
          },
        );
      }
    }
    else {
      const removedField = output.find(item => item.removed);
      namedSequence.push(
        {
          name: 'removed',
          value: output,
          date: input[i + 1].date,
        },
      );
    }
  }

  const compactedSequence = [];
  let temp_entry = {};
  let count = 0;
  for (let i = 0; i < namedSequence.length; i++) {
    if (i === 0) {
      temp_entry = {
        date_start: namedSequence[i].date,
        date_end: namedSequence[i].date,
        count: ++count,
        name: namedSequence[i].name,
      };
    }
    else if (i === namedSequence.length - 1) {
      if (namedSequence[i].name === temp_entry.name) {
        temp_entry.date_end = namedSequence[i].date;
        temp_entry.count = ++count;
        compactedSequence.push(temp_entry);
      }
      else if (namedSequence[i].name !== temp_entry.name) {
        compactedSequence.push(temp_entry);
        temp_entry = {
          date_start: namedSequence[i].date,
          date_end: namedSequence[i].date,
          count: 1,
          name: namedSequence[i].name,
        }
        ;
        compactedSequence.push(temp_entry);
      }
    }
    else if (namedSequence[i].name === temp_entry.name) {
      temp_entry.date_end = namedSequence[i].date;
      temp_entry.count = ++count;
    }
    else if (namedSequence[i].name !== temp_entry.name) {
      compactedSequence.push(temp_entry);
      count = 0;
      temp_entry = {
        date_start: namedSequence[i].date,
        date_end: namedSequence[i].date,
        count: ++count,
        name: namedSequence[i].name,
      };
    }
    else {
      compactedSequence.push(temp_entry);
      count = 0;
      temp_entry = {
        date_start: namedSequence[i].date,
        date_end: namedSequence[i].date,
        count: ++count,
        name: namedSequence[i].name,
      };
    }
  }

  compactedSequence.forEach(item => {
    if (item.name === 'designName') {
      item.name = 'Setting up Design Name';
    }
    else if (item.name === 'ivID') {
      item.name = 'Naming Independent Variable ID';
    }
    else if (item.name === 'ivName') {
      item.name = 'Setting Up IV name';
    }
    else if (item.name === 'levelName') {
      item.name = 'Setting Up Project';
    }
    else if (item.name === 'replications') {
      item.name = 'Adjusting Replication';
    }
    else if (item.name === 'numberOfParticipants') {
      item.name = 'Adjusting Name of Participants';
    }
    else if (item.name === 'counterbalancingStrategy') {
      item.name = 'Adjusting Counterbalancing Strategy';
    }
  });


  return {id, annotatedEvents: compactedSequence};
};

const addSequence = function* (action) {
  try {
    const oldHistory = yield select(getHistory);

    const annotation = oldHistory.history.map(item => doSequencing(item.id, item.events));
    // const annotation = yield call(doSequencing, oldHistory.history[33].id, oldHistory.history[33].events);

    yield put({
      type: HISTORY_END_ANNOTATION_ARRAY_SUCCESS,
      annotation,
    });
  }
  catch (e) {
    console.log('unable to parse sequence', e);
  }
};


const sequenceSaga = function* () {
  yield throttle(1, HISTORY_GET_SEQUENCE_START, addSequence);
};

export default sequenceSaga;
