import {put, select, takeEvery} from 'redux-saga/effects';
import {
  ITEM_DROPPEN_ON,
  ON_ITEM_DRAG,
  SET_ITEM_DRAG,
  UPDATE_PLAYGROUND,
} from './PlaygroundFieldAction';
import {getDragingItem, getPlaygroundData} from './selector';


/**
 * Blockly Sagas
 */

/**
 * This generator function
 *
 * @param {Object} action
 *
 */
const setItemDragged = function* (action) {
  try {
    const {dragItem} = action;
    yield put({
      type: SET_ITEM_DRAG,
      dragItem,
    });
  }
  catch (err) {
    console.log('Unable to set new lib', err);
  }
};

const updatePlaygroundData = function* (action) {
  try {
    const dragItem = yield select(getDragingItem);
    const playgroundData = yield select(getPlaygroundData);

    const data = playgroundData.map(item => {

      // TODO: remove if else with filter expression
      if (item.index === action.item.index) {
        return {
          index: item.index,
          component: dragItem.component,
        };
      } else if (item.index === dragItem.index) {
        return {
          index: item.index,
          component: action.item.component,
        };
      } else {
        return item;
      }
    });


    yield put({
      type: UPDATE_PLAYGROUND,
      data,
    });

  } catch (err) {
    console.log('unable to set new playground data', err);
  }
};

const playgroundFieldSaga = function* () {
  yield takeEvery(ON_ITEM_DRAG, setItemDragged);
  yield takeEvery(ITEM_DROPPEN_ON, updatePlaygroundData);

};

export default playgroundFieldSaga;
