import PropTypes from 'prop-types';
import React from 'react';
import {BlocklyWrapper} from '../components/modules/Blockly';
import {TrialCompare} from '../components/modules/TrialTable';
import {compareOptions, compareStyle} from '../store/workspace/shape';


class ComparisonBlock extends React.PureComponent {

  static propTypes = {
    data: PropTypes.object,
    toggle: PropTypes.bool,
  };


  _representation(xml) {

    if (!xml) {
      return (
        <div className="image__help"/>
      );
    } else {
      if (this.props.toggle) {
        return (
          <BlocklyWrapper
            workspaceXML={`<xml xmlns="http://www.w3.org/1999/xhtml">${xml}</xml>`}
            nonUpdating={true}
            workspaceStyle={compareStyle}
            injectOptions={compareOptions}
            forceUpdateProps={true}
          />
        );
      }
      else {
        const {trialTable, designName} = this.props.data;
        return (
          <TrialCompare
            design={{
              trialTable,
              designName,
            }}
          />
        );
      }
    }
  }


  render() {
    // TODO: Blockly call irreversiable
    const {xml} = this.props.data;
    return (
      <div className="canvas__item-compare">
        {
          this._representation(xml)
        }
      </div>
    );
  }
}


export default ComparisonBlock;
