import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {
  setForceUpdate,
  updateTSLData, updateWorkspace,
} from '../../../store/designs/DesignsAction';
import {
  updatePlayground,
  } from '../../../store/workspace/WorkspaceAction';
import BlocklyDrawer from './BlocklyDrawer';

/**
 * Visual representation of the trialTable
 */
class BlocklyOptimizationWrapper extends React.PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    workspace: PropTypes.any,
  };


  /**
   * Render function of react
   * @return {*}
   */
  render() {

    const {dispatch, workspace} = this.props;

    return (
      <>
        <BlocklyDrawer
          dispatch={dispatch}
          style={workspace.optiStyle}
          tools={workspace.tools}
          rawTsl={workspace.rawTsl}
          playground={workspace.playground}
          forceUpdate={false}
          forceUpdateCB={() => {
            dispatch(setForceUpdate(!workspace.forceUpdate));
          }}

          toolbox={workspace.toolbox}
          workspaceXML={workspace.workspaceXML}

          injectOptions={workspace.injectOptions}
          appearance={workspace.appearance}

          onCodeChange={(code) => {
            dispatch(updateTSLData(code));
          }}
          onXmlChange={(workspace) => {
            dispatch(updateWorkspace(workspace));
          }}

          onUpdate={() => 'variable that could update'}
        />
      </>

    );
  }
}

/**
 * Map state to props
 * @param {object} state
 * @return {{trialtable: *}}
 */
function mapStateToProps(state) {
  return {
    workspace: state.workspace,
  };
}

export default connect(mapStateToProps)(BlocklyOptimizationWrapper);
