import moment from 'moment';
import Blockly from 'node-blockly/browser';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {round} from '../../../constants/util';
import {clickOnBlock} from '../../../store/designs/DesignsAction';
import {BlocklyEvents} from './constants';
import {checkComment} from './blocks';

class BlocklyDrawer extends Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
    this.content = React.createRef();
    this.onResize = this.onResize.bind(this);
    const {tools} = this.props;
    this.initTools(tools);
  }


  componentDidMount() {
    const {injectOptions, toolbox} = this.props;

    window.addEventListener('resize', this.onResize, false);
    this.onResize();

    this.workspacePlayground = Blockly.inject(this.content.current, Object.assign({toolbox}, injectOptions));

    if (this.props.workspaceXML) {
      Blockly.Xml.domToWorkspace(
        Blockly.Xml.textToDom(this.props.workspaceXML),
        this.workspacePlayground,
      );
    }

    Blockly.svgResize(this.workspacePlayground);
    this.workspacePlayground.addChangeListener((e) => this.generateOutput(e));
  }


  /**
   * This lifecycle looks if the old and new state are different
   * Then it clears the lib and reloads with the props from the redux store
   * This allows to load dynamically new blocks from any new sources and display them in blockly
   * This method only works, because there exists the onCodeChange and OnXmlChange, without these 2
   * methods there would be a cycle of rerender and reupdate.
   * @param {object} prevProps
   */
  componentDidUpdate(prevProps) {
    // Compares the two workspaces and updates the lib when there is a change
    // There is also an external Variable that forces for a change
    const {forceUpdate, workspaceXML, forceUpdateCB, designData} = this.props;
    if (forceUpdate && prevProps.workspaceXML !== workspaceXML) {
      this.workspacePlayground.clear();
      Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(this.props.workspaceXML), this.workspacePlayground);
      // TODO: SET INDEPENDENT set forceupdate false
      forceUpdateCB();
    }

    if (designData) {
      // TODO: breaks when no design is in the workspace
      for (let i = 0; i < designData.length; i++) {
        const curDesign = designData[i];
        const designBrick = this.workspacePlayground.getBlockById(curDesign.designId);

        if (designBrick && curDesign && curDesign.multiple && curDesign.averageDuration && curDesign.interTrialTime) {
          // Set multiple number of participants based on counterbalancing
          const {multiple} = curDesign;
          if (designBrick && typeof designBrick.setFieldValue === 'function') {
            designBrick.setFieldValue(multiple, 'multipleCB');

            // Set order effect coverage
            const participants = parseInt(curDesign.numberOfParticipants);
            let orderEffectCoverage = 100;
            if (participants % multiple !== 0) {
              orderEffectCoverage = Math.round(round(participants % multiple / multiple, 2) * 100);
            }
            designBrick.setFieldValue(`${orderEffectCoverage}%`, 'orderEffectCoverage');

            // Set experiment duration
            const {averageDuration, interTrialTime} = curDesign;
            const [ttP1] = curDesign.trialTable;
            if (ttP1) {
              const seconds = ttP1.length * (averageDuration + interTrialTime);
              const timeFormatted = moment('2015-01-01')
                .startOf('day')
                .seconds(seconds)
                .format('HH:mm:ss');
              designBrick.setFieldValue(timeFormatted, 'duration');
            }
          }
        }
      }
    }
  }


  /**
   * Cleanup
   */
  componentWillUnmount() {
    window.removeEventListener(
      'resize',
      this.onResize,
    );

    // this.workspacePlayground.removeChangeListener((e) => this.generateOutput(e));
  }

  generateOutput(e) {
    this.handleEventData(e);
    this.generateWorkspaceOutput();
  }

  handleEventData(e) {
    const {dispatch} = this.props;


    // Here we listen to a click on a block to then dispatch a onclick event
    if (e.element === BlocklyEvents.CLICK) {
      dispatch(clickOnBlock(e.blockId));
    }
  }


  generateImage(workspace) {
    const canvas = workspace.svgBlockCanvas_.cloneNode(true);
    canvas.removeAttribute('width');
    canvas.removeAttribute('height');

    if (canvas.children[0] !== undefined) {
      canvas.removeAttribute('transform');
      canvas.children[0].removeAttribute('transform');
      canvas.children[0].children[0].removeAttribute('transform');
      const linkElm = document.createElementNS('http://www.w3.org/1999/xhtml', 'style');
      linkElm.textContent = `${Blockly.Css.CONTENT.join('')}\n\n`;
      canvas.insertBefore(linkElm, canvas.firstChild);

      const style = canvas.firstChild;
      const elements = [];
      const output = [];

      for (let i = 1; i < canvas.childNodes.length; i++) {
        elements.push(canvas.childNodes[i]);
      }

      elements.forEach(item => {
        const parent = workspace.svgBlockCanvas_.cloneNode(false);
        parent.removeAttribute('transform');
        parent.appendChild(style);
        parent.appendChild(item);

        if (document.getElementsByClassName('blocklyBlockCanvas').length > 0) {
          const bboxElem = [...document.querySelectorAll('.blocklyDraggable')].filter(entry => entry.dataset.id === item.dataset.id);
          const bbox = bboxElem[0].getBBox();
          let xml = new XMLSerializer().serializeToString(parent);
          xml = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${bbox.width}" height="${bbox.height}" viewBox="0 0 ${bbox.width * 2} ${bbox.height * 2}"><rect width="100%" height="100%" fill="transparent"></rect>${xml}</svg>`;

          output.push({
            designId: item.dataset.id,
            image: `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(xml)))}`,
          });
        }
      });

      return output;
    }
  }

  generateWorkspaceOutput() {
    const {rawTsl, workspaceXML, getWorkspaceChange} = this.props;

    let code = this.props.language.workspaceToCode(this.workspacePlayground);
    code = checkComment(code);
    const xml = Blockly.Xml.workspaceToDom(this.workspacePlayground);
    const xmlText = Blockly.Xml.domToText(xml);

    if (getWorkspaceChange) {
      getWorkspaceChange(xmlText);
    }


    if (code !== rawTsl) {
      this.props.onCodeChange(code);
    }

    if (xmlText !== workspaceXML) {
      const workspaceOutput = {
        xmlText,
        designImages: [], // this.generateImage(this.workspacePlayground),
      };
      this.props.onXmlChange(workspaceOutput);
    }
  }

  initTools(tools) {
    tools.forEach((tool) => {
      Blockly.Blocks[tool.name] = tool.block;
      Blockly.JavaScript[tool.name] = tool.generator;
    });
  }

  onResize() {
    this.content.current.style.width = `${this.wrapper.current.offsetWidth}px`;
    this.content.current.style.height = `${this.wrapper.current.offsetHeight}px`;
  }

  render() {
    const {style, className} = this.props;
    const wrapperStyle = {...style.wrapper, ...style.height};

    return (
      <div
        className={className}
        style={wrapperStyle}
        ref={this.wrapper}
      >
        <div
          style={style.content}
          ref={this.content}
        />
      </div>
    );
  }
}

BlocklyDrawer.defaultProps = {
  onCodeChange: () => {
  },
  onXmlChange: () => {
  },
  onUpdate: () => {
  },
  language: Blockly.JavaScript,
  tools: [],
  workspaceXML: '',
  injectOptions: {},
  appearance: {},
  className: '',
  style: {},
  rawTsl: '',
  forceUpdate: false,
};

BlocklyDrawer.propTypes = {
  appearance: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  designData: PropTypes.any,
  dispatch: PropTypes.func,
  forceUpdate: PropTypes.bool,
  forceUpdateCB: PropTypes.func,
  getWorkspaceChange: PropTypes.func,
  injectOptions: PropTypes.object,
  language: PropTypes.object.isRequired,
  onCodeChange: PropTypes.func,
  onXmlChange: PropTypes.func,
  playground: PropTypes.object,
  rawTsl: PropTypes.string,
  style: PropTypes.object,
  toolbox: PropTypes.string,
  tools: PropTypes.arrayOf(PropTypes.shape({
    block: PropTypes.shape({init: PropTypes.func}),
    category: PropTypes.string,
    generator: PropTypes.func,
    name: PropTypes.string,
  })).isRequired,
  workspaceXML: PropTypes.string,
};

export default BlocklyDrawer;
