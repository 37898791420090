import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import ComparisonBlock from '../components/ComparisonBlock';


class Compare extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      classNames: 'icon icon__circle icon__scale-hover',
      isToggleOn: true,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  static propTypes = {
    designData: PropTypes.any,
  };


  _representation(design) {

    return (
      <ComparisonBlock
        data={design}
        toggle={this.state.isToggleOn}
      />
    );
  }

  _renderDesigns(designData) {
    if (designData.length > 0) {
      return designData.map(design => (
        <div className="canvas__item-compare" key={design.designId}>

          {
            this._representation(design)
          }

        </div>
      ));
    }
    else {
      return (
        <div className="canvas__item-compare">
          Please return to the previous screen and select an experiment
        </div>
      );
    }
  }


  handleClick(e) {
    this.setState(state => ({
      classNames: (!state.isToggleOn) ? 'icon icon__circle icon__scale-hover' : 'icon icon__scale-hover icon__rotate icon__circle',
      isToggleOn: !state.isToggleOn,
    }));
  }


  render() {
    const {designData} = this.props;

    return (
      <>
        <a href="/">
          <div className='icon icon__scale-hover icon__close'/>
        </a>


        <div className="canvas canvas__container">
          <div className="canvas__header">
            <div onClick={this.handleClick} className={this.state.classNames}/>
          </div>
          {
            this._renderDesigns(designData)
          }
        </div>
      </>
    );
  }
}

/**
 * map State to Props
 * @param {object} state
 * @return {{designs: *}}
 */
function mapStateToProps(state: Object) {
  return {
    designData: state.designs.designData || [],
  };
}

export default connect(mapStateToProps)(Compare);
