import PropTypes from 'prop-types';
import React from 'react';

class InputList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {value: ''};


    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static propTypes = {
    className: PropTypes.string,
    confirm: PropTypes.func,
    entry: PropTypes.object,
    onSubmit: PropTypes.func,
    visible: PropTypes.bool,
  };

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
    const {confirm, entry, onSubmit} = this.props;

    confirm(entry, this.state.value, Date.now());
    onSubmit();
  }

  render() {
    const {visible, className} = this.props;
    if (visible) {
      return (
        <div className={`input ${className}`}>
          <form onSubmit={this.handleSubmit}>
            <input
              className="input__box" type="text" value={this.state.value}
              onChange={this.handleChange}
            />
            <input className="input__submit" type="submit" value="Submit"/>
          </form>
        </div>
      );
    }
    else {
      return (
        <></>
      );
    }
  }
}


export default InputList;
