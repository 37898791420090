export const playGroundInitData = [
  {
    index: 1,
    component: 'TrialPreview',
  },
  {
    index: 2,
    component: 'MiniChart',
  },
  {
    index: 3,
    component: 'Help',
  },
  {
    index: 4,
    component: 'nothing',
  },
  {
    index: 5,
    component: 'nothing',
  },
  {
    index: 6,
    component: 'nothing',
  },
  {
    index: 7,
    component: 'BlocklyOptimizationWrapper',
  },
  {
    index: 8,
    component: 'nothing',
  },
  {
    index: 9,
    component: 'nothing',
  },
];
