import Blockly from 'node-blockly/browser';
import {select, takeEvery} from 'redux-saga/effects';
import {getDesigns} from '../designs/selector';
import {SORT_BYIV_START} from './DataTransformationAction';


const sort = {
  IV: 'independentVariables',
};


const sortDesignByIV = function* (action) {
  try {

    // const {designId, Iv} = action;

    const designs = yield select(getDesigns);

    // Needs to be a IV
    console.log(designs.clickedBlockId, designs.clickedBlockType);

    const block = Blockly.getMainWorkspace().getBlockById(designs.clickedBlockId)
    const otherBlock = Blockly.getMainWorkspace().getBlockById('Iq*Ti,]T%*O9T,P|N52P')
    console.log(block, otherBlock);
    block.setParent(otherBlock);

    //const connectionthingy = new Blockly.Connection(Blockly.getMainWorkspace()
    // .getBlockById(designs.clickedBlockId), 1);
    //connectionthingy.connect(Blockly.getMainWorkspace()
    //  .getBlockById('Iq*Ti,]T%*O9T,P|N52P').getOutputConnection()[0]);
    // console.log(Blockly.Connection);

  }
  catch (err) {
    console.log(err, action);
  }
};

const dataTransformationSaga = function* () {
  yield takeEvery(SORT_BYIV_START, sortDesignByIV);
};

export default dataTransformationSaga;
