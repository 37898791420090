export default [
  "aardvark",
  "aardwolf",
  "abalone",
  "abyssiniancat",
  "abyssiniangroundhornbill",
  "acaciarat",
  "achillestang",
  "acornbarnacle",
  "acornweevil",
  "acornwoodpecker",
  "acouchi",
  "adamsstaghornedbeetle",
  "addax",
  "adder",
  "adeliepenguin",
  "admiralbutterfly",
  "adouri",
  "aegeancat",
  "affenpinscher",
  "afghanhound",
  "africanaugurbuzzard",
  "africanbushviper",
  "africancivet",
  "africanclawedfrog",
  "africanelephant",
  "africanfisheagle",
  "africangoldencat",
  "africangroundhornbill",
  "africanharrierhawk",
  "africanhornbill",
  "africanjacana",
  "africanmolesnake",
  "africanparadiseflycatcher",
  "africanpiedkingfisher",
  "africanporcupine",
  "africanrockpython",
  "africanwildcat",
  "africanwilddog",
  "agama",
  "agouti",
  "aidi",
  "airedale",
  "airedaleterrier",
  "akitainu",
  "alabamamapturtle",
  "alaskajingle",
  "alaskanhusky",
  "alaskankleekai",
  "alaskanmalamute",
  "albacoretuna",
  "albatross",
  "albertosaurus",
  "albino",
  "aldabratortoise",
  "allensbigearedbat",
  "alleycat",
  "alligator",
  "alligatorgar",
  "alligatorsnappingturtle",
  "allosaurus",
  "alpaca",
  "alpinegoat",
  "alpineroadguidetigerbeetle",
  "altiplanochinchillamouse",
  "amazondolphin",
  "amazonparrot",
  "amazontreeboa",
  "amberpenshell",
  "ambushbug",
  "americanalligator",
  "americanavocet",
  "americanbadger",
  "americanbittern",
  "americanblackvulture",
  "americanbobtail",
  "americanbulldog",
  "americancicada",
  "americancrayfish",
  "americancreamdraft",
  "americancrocodile",
  "americancrow",
  "americancurl",
  "americangoldfinch",
  "americanindianhorse",
  "americankestrel",
  "americanlobster",
  "americanmarten",
  "americanpainthorse",
  "americanquarterhorse",
  "americanratsnake",
  "americanredsquirrel",
  "americanriverotter",
  "americanrobin",
  "americansaddlebred",
  "americanshorthair",
  "americantoad",
  "americanwarmblood",
  "americanwigeon",
  "americanwirehair",
  "amethystgemclam",
  "amethystinepython",
  "amethystsunbird",
  "ammonite",
  "amoeba",
  "amphibian",
  "amphiuma",
  "amurminnow",
  "amurratsnake",
  "amurstarfish",
  "anaconda",
  "anchovy",
  "andalusianhorse",
  "andeancat",
  "andeancockoftherock",
  "andeancondor",
  "anemone",
  "anemonecrab",
  "anemoneshrimp",
  "angelfish",
  "angelwingmussel",
  "anglerfish",
  "angora",
  "angwantibo",
  "anhinga",
  "ankole",
  "ankolewatusi",
  "annashummingbird",
  "annelid",
  "annelida",
  "anole",
  "anophelesmosquito",
  "ant",
  "antarcticfurseal",
  "antarcticgiantpetrel",
  "antbear",
  "anteater",
  "antelope",
  "antelopegroundsquirrel",
  "antipodesgreenparakeet",
  "antlion",
  "anura",
  "aoudad",
  "apatosaur",
  "ape",
  "aphid",
  "apisdorsatalaboriosa",
  "aplomadofalcon",
  "appaloosa",
  "aquaticleech",
  "arabianhorse",
  "arabianoryx",
  "arabianwildcat",
  "aracari",
  "arachnid",
  "arawana",
  "archaeocete",
  "archaeopteryx",
  "archerfish",
  "arcticduck",
  "arcticfox",
  "arctichare",
  "arcticseal",
  "arcticwolf",
  "argali",
  "argentinehornedfrog",
  "argentineruddyduck",
  "argusfish",
  "arieltoucan",
  "arizonaalligatorlizard",
  "arkshell",
  "armadillo",
  "armedcrab",
  "armednylonshrimp",
  "armyant",
  "armyworm",
  "arrowana",
  "arrowcrab",
  "arrowworm",
  "arthropods",
  "aruanas",
  "asianconstablebutterfly",
  "asiandamselfly",
  "asianelephant",
  "asianlion",
  "asianpiedstarling",
  "asianporcupine",
  "asiansmallclawedotter",
  "asiantrumpetfish",
  "asianwaterbuffalo",
  "asiaticgreaterfreshwaterclam",
  "asiaticlesserfreshwaterclam",
  "asiaticmouflon",
  "asiaticwildass",
  "asp",
  "ass",
  "assassinbug",
  "astarte",
  "astrangiacoral",
  "atlanticblackgoby",
  "atlanticbluetang",
  "atlanticridleyturtle",
  "atlanticsharpnosepuffer",
  "atlanticspadefish",
  "atlasmoth",
  "attwatersprairiechicken",
  "auk",
  "auklet",
  "aurochs",
  "australiancattledog",
  "australiancurlew",
  "australianfreshwatercrocodile",
  "australianfurseal",
  "australiankelpie",
  "australiankestrel",
  "australianshelduck",
  "australiansilkyterrier",
  "austrianpinscher",
  "avians",
  "avocet",
  "axisdeer",
  "axolotl",
  "ayeaye",
  "aztecant",
  "azurevase",
  "azurevasesponge",
  "azurewingedmagpie",
  "babirusa",
  "baboon",
  "backswimmer",
  "bactrian",
  "badger",
  "bagworm",
  "baiji",
  "baldeagle",
  "baleenwhale",
  "balloonfish",
  "ballpython",
  "bandicoot",
  "bangeltiger",
  "bantamrooster",
  "banteng",
  "barasinga",
  "barasingha",
  "barb",
  "barbet",
  "barebirdbat",
  "barnacle",
  "barnowl",
  "barnswallow",
  "barracuda",
  "basenji",
  "basil",
  "basilisk",
  "bass",
  "bassethound",
  "bat",
  "bats",
  "beagle",
  "bear",
  "beardedcollie",
  "beardeddragon",
  "beauceron",
  "beaver",
  "bedbug",
  "bedlingtonterrier",
  "bee",
  "beetle",
  "bellfrog",
  "bellsnake",
  "belugawhale",
  "bengaltiger",
  "bergerpicard",
  "bernesemountaindog",
  "betafish",
  "bettong",
  "bichonfrise",
  "bighorn",
  "bighornedsheep",
  "bighornsheep",
  "bigmouthbass",
  "bilby",
  "billygoat",
  "binturong",
  "bird",
  "birdofparadise",
  "bison",
  "bittern",
  "blackandtancoonhound",
  "blackbear",
  "blackbird",
  "blackbuck",
  "blackcrappie",
  "blackfish",
  "blackfly",
  "blackfootedferret",
  "blacklab",
  "blacklemur",
  "blackmamba",
  "blacknorwegianelkhound",
  "blackpanther",
  "blackrhino",
  "blackrussianterrier",
  "blackwidowspider",
  "blesbok",
  "blobfish",
  "blowfish",
  "blueandgoldmackaw",
  "bluebird",
  "bluebottle",
  "bluebottlejellyfish",
  "bluebreastedkookaburra",
  "bluefintuna",
  "bluefish",
  "bluegill",
  "bluejay",
  "bluemorphobutterfly",
  "blueshark",
  "bluet",
  "bluetickcoonhound",
  "bluetonguelizard",
  "bluewhale",
  "boa",
  "boaconstrictor",
  "boar",
  "bobcat",
  "bobolink",
  "bobwhite",
  "boilweevil",
  "bongo",
  "bonobo",
  "booby",
  "bordercollie",
  "borderterrier",
  "borer",
  "borzoi",
  "boto",
  "boubou",
  "boutu",
  "bovine",
  "brahmanbull",
  "brahmancow",
  "brant",
  "bream",
  "brocketdeer",
  "bronco",
  "brontosaurus",
  "brownbear",
  "brownbutterfly",
  "bubblefish",
  "buck",
  "buckeyebutterfly",
  "budgie",
  "bufeo",
  "buffalo",
  "bufflehead",
  "bug",
  "bull",
  "bullfrog",
  "bullmastiff",
  "bumblebee",
  "bunny",
  "bunting",
  "burro",
  "bushbaby",
  "bushsqueaker",
  "bustard",
  "butterfly",
  "buzzard",
  "caecilian",
  "caiman",
  "caimanlizard",
  "calf",
  "camel",
  "canadagoose",
  "canary",
  "canine",
  "canvasback",
  "capeghostfrog",
  "capybara",
  "caracal",
  "cardinal",
  "caribou",
  "carp",
  "carpenterant",
  "cassowary",
  "cat",
  "catbird",
  "caterpillar",
  "catfish",
  "cats",
  "cattle",
  "caudata",
  "cavy",
  "centipede",
  "cero",
  "chafer",
  "chameleon",
  "chamois",
  "chanticleer",
  "cheetah",
  "chevrotain",
  "chick",
  "chickadee",
  "chicken",
  "chihuahua",
  "chimneyswift",
  "chimpanzee",
  "chinchilla",
  "chinesecrocodilelizard",
  "chipmunk",
  "chital",
  "chrysalis",
  "chrysomelid",
  "chuckwalla",
  "chupacabra",
  "cicada",
  "cirriped",
  "civet",
  "clam",
  "cleanerwrasse",
  "clingfish",
  "clownanemonefish",
  "clumber",
  "coati",
  "cob",
  "cobra",
  "cock",
  "cockatiel",
  "cockatoo",
  "cockerspaniel",
  "cockroach",
  "cod",
  "coelacanth",
  "collardlizard",
  "collie",
  "colt",
  "comet",
  "commabutterfly",
  "commongonolek",
  "conch",
  "condor",
  "coney",
  "conure",
  "cony",
  "coot",
  "cooter",
  "copepod",
  "copperbutterfly",
  "copperhead",
  "coqui",
  "coral",
  "cormorant",
  "cornsnake",
  "corydorascatfish",
  "cottonmouth",
  "cottontail",
  "cougar",
  "cow",
  "cowbird",
  "cowrie",
  "coyote",
  "coypu",
  "crab",
  "crane",
  "cranefly",
  "crayfish",
  "creature",
  "cricket",
  "crocodile",
  "crocodileskink",
  "crossbill",
  "crow",
  "crownofthornsstarfish",
  "crustacean",
  "cub",
  "cuckoo",
  "cur",
  "curassow",
  "curlew",
  "cuscus",
  "cusimanse",
  "cuttlefish",
  "cutworm",
  "cygnet",
  "dachshund",
  "daddylonglegs",
  "dairycow",
  "dalmatian",
  "damselfly",
  "danishswedishfarmdog",
  "darklingbeetle",
  "dartfrog",
  "darwinsfox",
  "dassie",
  "dassierat",
  "davidstiger",
  "deer",
  "deermouse",
  "degu",
  "degus",
  "deinonychus",
  "desertpupfish",
  "devilfish",
  "deviltasmanian",
  "diamondbackrattlesnake",
  "dikdik",
  "dikkops",
  "dingo",
  "dinosaur",
  "diplodocus",
  "dipper",
  "discus",
  "dobermanpinscher",
  "doctorfish",
  "dodo",
  "dodobird",
  "doe",
  "dog",
  "dogfish",
  "dogwoodclubgall",
  "dogwoodtwigborer",
  "dolphin",
  "donkey",
  "dorado",
  "dore",
  "dorking",
  "dormouse",
  "dotterel",
  "douglasfirbarkbeetle",
  "dove",
  "dowitcher",
  "drafthorse",
  "dragon",
  "dragonfly",
  "drake",
  "drever",
  "dromaeosaur",
  "dromedary",
  "drongo",
  "duck",
  "duckbillcat",
  "duckbillplatypus",
  "duckling",
  "dugong",
  "duiker",
  "dungbeetle",
  "dungenesscrab",
  "dunlin",
  "dunnart",
  "dutchshepherddog",
  "dutchsmoushond",
  "dwarfmongoose",
  "dwarfrabbit",
  "eagle",
  "earthworm",
  "earwig",
  "easternglasslizard",
  "easternnewt",
  "easteuropeanshepherd",
  "eastrussiancoursinghounds",
  "eastsiberianlaika",
  "echidna",
  "eel",
  "eelelephant",
  "eeve",
  "eft",
  "egg",
  "egret",
  "eider",
  "eidolonhelvum",
  "ekaltadeta",
  "eland",
  "electriceel",
  "elephant",
  "elephantbeetle",
  "elephantseal",
  "elk",
  "elkhound",
  "elver",
  "emeraldtreeskink",
  "emperorpenguin",
  "emperorshrimp",
  "emu",
  "englishpointer",
  "englishsetter",
  "equestrian",
  "equine",
  "erin",
  "ermine",
  "erne",
  "eskimodog",
  "esok",
  "estuarinecrocodile",
  "ethiopianwolf",
  "europeanfiresalamander",
  "europeanpolecat",
  "ewe",
  "eyas",
  "eyelashpitviper",
  "eyra",
  "fairybluebird",
  "fairyfly",
  "falcon",
  "fallowdeer",
  "fantail",
  "fanworms",
  "fattaileddunnart",
  "fawn",
  "feline",
  "fennecfox",
  "ferret",
  "fiddlercrab",
  "fieldmouse",
  "fieldspaniel",
  "finch",
  "finnishspitz",
  "finwhale",
  "fireant",
  "firebelliedtoad",
  "firecrest",
  "firefly",
  "fish",
  "fishingcat",
  "flamingo",
  "flatcoatretriever",
  "flatfish",
  "flea",
  "flee",
  "flicker",
  "flickertailsquirrel",
  "flies",
  "flounder",
  "fluke",
  "fly",
  "flycatcher",
  "flyingfish",
  "flyingfox",
  "flyinglemur",
  "flyingsquirrel",
  "foal",
  "fossa",
  "fowl",
  "fox",
  "foxhound",
  "foxterrier",
  "frenchbulldog",
  "freshwatereel",
  "frigatebird",
  "frilledlizard",
  "frillneckedlizard",
  "fritillarybutterfly",
  "frog",
  "frogmouth",
  "fruitbat",
  "fruitfly",
  "fugu",
  "fulmar",
  "funnelweaverspider",
  "furseal",
  "gadwall",
  "galago",
  "galah",
  "galapagosalbatross",
  "galapagosdove",
  "galapagoshawk",
  "galapagosmockingbird",
  "galapagospenguin",
  "galapagossealion",
  "galapagostortoise",
  "gallinule",
  "gallowaycow",
  "gander",
  "gangesdolphin",
  "gannet",
  "gar",
  "gardensnake",
  "garpike",
  "gartersnake",
  "gaur",
  "gavial",
  "gazelle",
  "gecko",
  "geese",
  "gelada",
  "gelding",
  "gemsbok",
  "gemsbuck",
  "genet",
  "gentoopenguin",
  "gerbil",
  "gerenuk",
  "germanpinscher",
  "germanshepherd",
  "germanshorthairedpointer",
  "germanspaniel",
  "germanspitz",
  "germanwirehairedpointer",
  "gharial",
  "ghostshrimp",
  "giantschnauzer",
  "gibbon",
  "gilamonster",
  "giraffe",
  "glassfrog",
  "globefish",
  "glowworm",
  "gnat",
  "gnatcatcher",
  "gnu",
  "goa",
  "goat",
  "godwit",
  "goitered",
  "goldeneye",
  "goldenmantledgroundsquirrel",
  "goldenretriever",
  "goldfinch",
  "goldfish",
  "gonolek",
  "goose",
  "goosefish",
  "gopher",
  "goral",
  "gordonsetter",
  "gorilla",
  "goshawk",
  "gosling",
  "gossamerwingedbutterfly",
  "gourami",
  "grackle",
  "grasshopper",
  "grassspider",
  "grayfox",
  "grayling",
  "grayreefshark",
  "graysquirrel",
  "graywolf",
  "greatargus",
  "greatdane",
  "greathornedowl",
  "greatwhiteshark",
  "grebe",
  "greendarnerdragonfly",
  "greyhounddog",
  "grison",
  "grizzlybear",
  "grosbeak",
  "groundbeetle",
  "groundhog",
  "grouper",
  "grouse",
  "grub",
  "grunion",
  "guanaco",
  "guernseycow",
  "guillemot",
  "guineafowl",
  "guineapig",
  "gull",
  "guppy",
  "gypsymoth",
  "gyrfalcon",
  "hackee",
  "haddock",
  "hadrosaurus",
  "hagfish",
  "hairstreak",
  "hairstreakbutterfly",
  "hake",
  "halcyon",
  "halibut",
  "halicore",
  "hamadryad",
  "hamadryas",
  "hammerheadbird",
  "hammerheadshark",
  "hammerkop",
  "hamster",
  "hanumanmonkey",
  "hapuka",
  "hapuku",
  "harborporpoise",
  "harborseal",
  "hare",
  "harlequinbug",
  "harpseal",
  "harpyeagle",
  "harrier",
  "harrierhawk",
  "hart",
  "hartebeest",
  "harvestmen",
  "harvestmouse",
  "hatchetfish",
  "hawaiianmonkseal",
  "hawk",
  "hectorsdolphin",
  "hedgehog",
  "heifer",
  "hellbender",
  "hen",
  "herald",
  "herculesbeetle",
  "hermitcrab",
  "heron",
  "herring",
  "heterodontosaurus",
  "hind",
  "hippopotamus",
  "hoatzin",
  "hochstettersfrog",
  "hog",
  "hogget",
  "hoiho",
  "hoki",
  "homalocephale",
  "honeybadger",
  "honeybee",
  "honeycreeper",
  "honeyeater",
  "hookersealion",
  "hoopoe",
  "hornbill",
  "hornedtoad",
  "hornedviper",
  "hornet",
  "hornshark",
  "horse",
  "horsechestnutleafminer",
  "horsefly",
  "horsemouse",
  "horseshoebat",
  "horseshoecrab",
  "hound",
  "housefly",
  "hoverfly",
  "howlermonkey",
  "huemul",
  "huia",
  "human",
  "hummingbird",
  "humpbackwhale",
  "husky",
  "hydatidtapeworm",
  "hydra",
  "hyena",
  "hylaeosaurus",
  "hypacrosaurus",
  "hypsilophodon",
  "hyracotherium",
  "hyrax",
  "iaerismetalmark",
  "ibadanmalimbe",
  "iberianbarbel",
  "iberianchiffchaff",
  "iberianemeraldlizard",
  "iberianlynx",
  "iberianmidwifetoad",
  "iberianmole",
  "iberiannase",
  "ibex",
  "ibis",
  "ibisbill",
  "ibizanhound",
  "iceblueredtopzebra",
  "icefish",
  "icelandgull",
  "icelandichorse",
  "icelandicsheepdog",
  "ichidna",
  "ichneumonfly",
  "ichthyosaurs",
  "ichthyostega",
  "icterinewarbler",
  "iggypops",
  "iguana",
  "iguanodon",
  "illadopsis",
  "ilsamochadegu",
  "imago",
  "impala",
  "imperatorangel",
  "imperialeagle",
  "incatern",
  "inchworm",
  "indianabat",
  "indiancow",
  "indianelephant",
  "indianglassfish",
  "indianhare",
  "indianjackal",
  "indianpalmsquirrel",
  "indianpangolin",
  "indianrhinoceros",
  "indianringneckparakeet",
  "indianrockpython",
  "indianskimmer",
  "indianspinyloach",
  "indigobunting",
  "indigowingedparrot",
  "indochinahogdeer",
  "indochinesetiger",
  "indri",
  "indusriverdolphin",
  "inexpectatumpleco",
  "inganue",
  "insect",
  "intermediateegret",
  "invisiblerail",
  "iraniangroundjay",
  "iridescentshark",
  "iriomotecat",
  "irishdraughthorse",
  "irishredandwhitesetter",
  "irishsetter",
  "irishterrier",
  "irishwaterspaniel",
  "irishwolfhound",
  "irrawaddydolphin",
  "irukandjijellyfish",
  "isabellineshrike",
  "isabellinewheatear",
  "islandcanary",
  "islandwhistler",
  "isopod",
  "italianbrownbear",
  "italiangreyhound",
  "ivorybackedwoodswallow",
  "ivorybilledwoodpecker",
  "ivorygull",
  "izuthrush",
  "jabiru",
  "jackal",
  "jackrabbit",
  "jaeger",
  "jaguar",
  "jaguarundi",
  "janenschia",
  "japanesebeetle",
  "javalina",
  "jay",
  "jellyfish",
  "jenny",
  "jerboa",
  "joey",
  "johndory",
  "juliabutterfly",
  "jumpingbean",
  "junco",
  "junebug",
  "kagu",
  "kakapo",
  "kakarikis",
  "kangaroo",
  "karakul",
  "katydid",
  "kawala",
  "kentrosaurus",
  "kestrel",
  "kid",
  "killdeer",
  "killerwhale",
  "killifish",
  "kingbird",
  "kingfisher",
  "kinglet",
  "kingsnake",
  "kinkajou",
  "kiskadee",
  "kissingbug",
  "kite",
  "kitfox",
  "kitten",
  "kittiwake",
  "kitty",
  "kiwi",
  "koala",
  "koalabear",
  "kob",
  "kodiakbear",
  "koi",
  "komododragon",
  "koodoo",
  "kookaburra",
  "kouprey",
  "krill",
  "kronosaurus",
  "kudu",
  "kusimanse",
  "labradorretriever",
  "lacewing",
  "ladybird",
  "ladybug",
  "lamb",
  "lamprey",
  "langur",
  "lark",
  "larva",
  "laughingthrush",
  "lcont",
  "leafbird",
  "leafcutterant",
  "leafhopper",
  "leafwing",
  "leech",
  "lemming",
  "lemur",
  "leonberger",
  "leopard",
  "leopardseal",
  "leveret",
  "lhasaapso",
  "lice",
  "liger",
  "lightningbug",
  "limpet",
  "limpkin",
  "ling",
  "lion",
  "lionfish",
  "littlenightmonkeys",
  "lizard",
  "llama",
  "lobo",
  "lobster",
  "locust",
  "loggerheadturtle",
  "longhorn",
  "longhornbeetle",
  "longspur",
  "loon",
  "lorikeet",
  "loris",
  "louse",
  "lovebird",
  "lowchen",
  "lunamoth",
  "lungfish",
  "lynx ",
  "lynx",
  "macaque",
  "macaw",
  "macropod",
  "madagascarhissingroach",
  "maggot",
  "magpie",
  "maiasaura",
  "majungatholus",
  "malamute",
  "mallard",
  "maltesedog",
  "mamba",
  "mamenchisaurus",
  "mammal",
  "mammoth",
  "manatee",
  "mandrill",
  "mangabey",
  "manta",
  "mantaray",
  "mantid",
  "mantis",
  "mantisray",
  "manxcat",
  "mara",
  "marabou",
  "marbledmurrelet",
  "mare",
  "marlin",
  "marmoset",
  "marmot",
  "marten",
  "martin",
  "massasauga",
  "massospondylus",
  "mastiff",
  "mastodon",
  "mayfly",
  "meadowhawk",
  "meadowlark",
  "mealworm",
  "meerkat",
  "megalosaurus",
  "megalotomusquinquespinosus",
  "megaraptor",
  "merganser",
  "merlin",
  "metalmarkbutterfly",
  "metamorphosis",
  "mice",
  "microvenator",
  "midge",
  "milksnake",
  "milkweedbug",
  "millipede",
  "minibeast",
  "mink",
  "minnow",
  "mite",
  "moa",
  "mockingbird",
  "mole",
  "mollies",
  "mollusk",
  "molly",
  "monarch",
  "mongoose",
  "mongrel",
  "monkey",
  "monkfish ",
  "monoclonius",
  "montanoceratops",
  "moorhen",
  "moose",
  "moray",
  "morayeel",
  "morpho",
  "mosasaur",
  "mosquito",
  "moth",
  "motmot",
  "mouflon",
  "mountaincat",
  "mountainlion",
  "mouse",
  "mouse/mice",
  "mousebird",
  "mudpuppy",
  "mule",
  "mullet",
  "muntjac",
  "murrelet",
  "muskox",
  "muskrat",
  "mussaurus",
  "mussel",
  "mustang",
  "mutt",
  "myna",
  "mynah",
  "myotis ",
  "nabarlek",
  "nag",
  "naga",
  "nagapies",
  "nakedmolerat",
  "nandine",
  "nandoo",
  "nandu",
  "narwhal",
  "narwhale",
  "natterjacktoad",
  "nauplius",
  "nautilus",
  "needlefish",
  "needletail",
  "nematode",
  "nene",
  "neonblueguppy",
  "neonbluehermitcrab",
  "neondwarfgourami",
  "neonrainbowfish",
  "neonredguppy",
  "neontetra",
  "nerka",
  "nettlefish",
  "newfoundlanddog",
  "newt",
  "newtnutria",
  "nightcrawler",
  "nighthawk",
  "nightheron",
  "nightingale",
  "nightjar",
  "nijssenissdwarfchihlid",
  "nilgai",
  "ninebandedarmadillo",
  "noctilio",
  "noctule",
  "noddy",
  "noolbenger",
  "northerncardinals",
  "northernelephantseal",
  "northernflyingsquirrel",
  "northernfurseal",
  "northernhairynosedwombat",
  "northernpike",
  "northernseahorse",
  "northernspottedowl",
  "norwaylobster",
  "norwayrat",
  "nubiangoat",
  "nudibranch",
  "numbat",
  "nurseshark",
  "nutcracker",
  "nuthatch",
  "nutria",
  "nyala",
  "nymph",
  "ocelot",
  "octopus",
  "okapi",
  "olingo",
  "olm",
  "opossum",
  "orangutan",
  "orca",
  "oregonsilverspotbutterfly",
  "oriole",
  "oropendola",
  "oropendula",
  "oryx",
  "osprey",
  "ostracod",
  "ostrich",
  "otter",
  "ovenbird",
  "owl",
  "owlbutterfly",
  "ox",
  "oxen",
  "oxpecker",
  "oyster",
  "ozarkbigearedbat",
  "paca ",
  "pachyderm",
  "pacificparrotlet",
  "paddlefish",
  "paintedladybutterfly",
  "panda",
  "pangolin",
  "panther",
  "paperwasp",
  "papillon",
  "parakeet",
  "parrot",
  "partridge",
  "peacock",
  "peafowl",
  "peccary",
  "pekingese",
  "pelican",
  "pelicinuspetrel",
  "penguin",
  "perch",
  "peregrinefalcon",
  "pewee",
  "phalarope",
  "pharaohhound",
  "pheasant",
  "phoebe",
  "phoenix",
  "pig",
  "pigeon",
  "piglet",
  "pika",
  "pike",
  "pikeperch ",
  "pilchard",
  "pinemarten",
  "pinkriverdolphin",
  "pinniped",
  "pintail",
  "pipistrelle",
  "pipit",
  "piranha",
  "pitbull",
  "pittabird",
  "plainsqueaker",
  "plankton",
  "planthopper",
  "platypus",
  "plover",
  "polarbear",
  "polecat",
  "polliwog",
  "polyp",
  "polyturator",
  "pomeranian",
  "pondskater",
  "pony",
  "pooch",
  "poodle",
  "porcupine",
  "porpoise",
  "portuguesemanofwar",
  "possum",
  "prairiedog",
  "prawn",
  "prayingmantid",
  "prayingmantis",
  "primate",
  "pronghorn",
  "pseudodynerusquadrisectus",
  "ptarmigan",
  "pterodactyls",
  "pterosaurs",
  "puffer",
  "pufferfish",
  "puffin",
  "pug",
  "pullet",
  "puma",
  "pupa",
  "pupfish",
  "puppy",
  "purplemarten",
  "pussycat",
  "pygmy",
  "python",
  "quadrisectus",
  "quagga",
  "quahog",
  "quail",
  "queenalexandrasbirdwing",
  "queenalexandrasbirdwingbutterfly",
  "queenant",
  "queenbee",
  "queenconch",
  "queenslandgrouper",
  "queenslandheeler",
  "queensnake",
  "quelea",
  "quetzal",
  "quetzalcoatlus",
  "quillback",
  "quinquespinosus",
  "quokka",
  "quoll",
  "rabbit",
  "rabidsquirrel",
  "raccoon",
  "racer",
  "racerunner",
  "ragfish",
  "rail",
  "rainbowfish",
  "rainbowlorikeet",
  "rainbowtrout",
  "ram",
  "raptors",
  "rasbora",
  "rat",
  "ratfish",
  "rattail",
  "rattlesnake",
  "raven",
  "ray",
  "redhead",
  "redheadedwoodpecker",
  "redpoll",
  "redstart",
  "redtailedhawk",
  "reindeer",
  "reptile",
  "reynard",
  "rhea",
  "rhesusmonkey",
  "rhino",
  "rhinoceros",
  "rhinocerosbeetle",
  "rhodesianridgeback",
  "ringtailedlemur",
  "ringworm",
  "riograndeescuerzo",
  "roach",
  "roadrunner",
  "roan",
  "robberfly",
  "robin",
  "rockrat",
  "rodent",
  "roebuck",
  "roller",
  "rook",
  "rooster",
  "rottweiler",
  "sable",
  "sableantelope",
  "sablefish ",
  "saiga",
  "sakimonkey",
  "salamander",
  "salmon",
  "saltwatercrocodile",
  "sambar",
  "samoyeddog",
  "sandbarshark",
  "sanddollar",
  "sanderling",
  "sandpiper",
  "sapsucker",
  "sardine",
  "sawfish",
  "scallop",
  "scarab",
  "scarletibis",
  "scaup",
  "schapendoes",
  "schipperke",
  "schnauzer",
  "scorpion",
  "scoter",
  "screamer",
  "seabird",
  "seagull",
  "seahog",
  "seahorse",
  "seal",
  "sealion",
  "seamonkey",
  "seaslug",
  "seaurchin",
  "senegalpython",
  "seriema",
  "serpent",
  "serval",
  "shark",
  "shearwater",
  "sheep",
  "sheldrake",
  "shelduck",
  "shibainu",
  "shihtzu",
  "shorebird",
  "shoveler",
  "shrew",
  "shrike",
  "shrimp",
  "siamang",
  "siamesecat",
  "siberiantiger",
  "sidewinder",
  "sifaka",
  "silkworm",
  "silverfish",
  "silverfox",
  "silversidefish",
  "siskin",
  "skimmer",
  "skink",
  "skipper",
  "skua",
  "skunk",
  "skylark",
  "sloth",
  "slothbear",
  "slug",
  "smelts",
  "smew",
  "snail",
  "snake",
  "snipe",
  "snoutbutterfly",
  "snowdog",
  "snowgeese",
  "snowleopard",
  "snowmonkey",
  "snowyowl",
  "sockeyesalmon",
  "solenodon",
  "solitaire",
  "songbird",
  "sora",
  "southernhairnosedwombat",
  "sow",
  "spadefoot",
  "sparrow",
  "sphinx",
  "spider",
  "spidermonkey",
  "spiketail",
  "spittlebug",
  "sponge",
  "spoonbill",
  "spotteddolphin",
  "spreadwing",
  "springbok",
  "springpeeper",
  "springtail",
  "squab",
  "squamata",
  "squeaker",
  "squid",
  "squirrel",
  "stag",
  "stagbeetle",
  "stallion",
  "starfish",
  "starling",
  "steed",
  "steer",
  "stegosaurus",
  "stickinsect",
  "stickleback",
  "stilt",
  "stingray",
  "stinkbug",
  "stinkpot",
  "stoat",
  "stonefly",
  "stork",
  "stud",
  "sturgeon",
  "sugarglider",
  "sulphurbutterfly",
  "sunbear",
  "sunbittern",
  "sunfish",
  "swallow",
  "swallowtail",
  "swallowtailbutterfly",
  "swan",
  "swellfish",
  "swift",
  "swordfish",
  "tadpole",
  "tahr",
  "takin",
  "tamarin",
  "tanager",
  "tapaculo",
  "tapeworm",
  "tapir",
  "tarantula",
  "tarpan",
  "tarsier",
  "taruca",
  "tasmaniandevil",
  "tasmaniantiger",
  "tattler",
  "tayra",
  "teal",
  "tegus",
  "teledu",
  "tench",
  "tenrec",
  "termite",
  "tern",
  "terrapin",
  "terrier",
  "thoroughbred",
  "thrasher",
  "thrip",
  "thrush",
  "thunderbird",
  "thylacine",
  "tick",
  "tiger",
  "tigerbeetle",
  "tigermoth",
  "tigershark",
  "tilefish",
  "tinamou",
  "titi",
  "titmouse",
  "toad",
  "toadfish",
  "tomtit ",
  "topi",
  "tortoise",
  "toucan",
  "towhee",
  "tragopan",
  "treecreeper",
  "trex",
  "triceratops",
  "trogon",
  "trout",
  "trumpeterbird",
  "trumpeterswan",
  "tsetsefly",
  "tuatara",
  "tuna",
  "turaco",
  "turkey",
  "turnstone",
  "turtle",
  "turtledove",
  "uakari",
  "ugandakob",
  "uintagroundsquirrel",
  "ulyssesbutterfly",
  "umbrellabird",
  "umbrette",
  "unau",
  "ungulate",
  "unicorn",
  "upupa",
  "urchin",
  "urial",
  "uromastyxmaliensis",
  "uromastyxspinipes",
  "urson",
  "urubu",
  "urus",
  "urutu",
  "urva",
  "utahprairiedog",
  "vampirebat",
  "vaquita",
  "veery",
  "velociraptor",
  "velvetcrab",
  "velvetworm",
  "venomoussnake",
  "verdin",
  "vervet",
  "viceroybutterfly",
  "vicuna",
  "viper",
  "viperfish",
  "vipersquid",
  "vireo",
  "virginiaopossum",
  "vixen",
  "vole",
  "volvox",
  "vulpesvelox",
  "vulpesvulpes",
  "vulture",
  "walkingstick",
  "wallaby",
  "wallaroo",
  "walleye",
  "walrus",
  "warbler",
  "warthog",
  "wasp",
  "waterboatman",
  "waterbuck",
  "waterbuffalo",
  "waterbug",
  "waterdogs",
  "waterdragons",
  "watermoccasin",
  "waterstrider",
  "waterthrush",
  "wattlebird",
  "watussi",
  "waxwing",
  "weasel",
  "weaverbird",
  "weevil",
  "westafricanantelope",
  "whale",
  "whapuku",
  "whelp",
  "whimbrel",
  "whippet",
  "whippoorwill",
  "whitebeakeddolphin",
  "whiteeye",
  "whitepelican",
  "whiterhino",
  "whitetaileddeer",
  "whitetippedreefshark",
  "whooper",
  "whoopingcrane",
  "widgeon",
  "widowspider",
  "wildcat",
  "wildebeast",
  "wildebeest",
  "willet",
  "wireworm",
  "wisent",
  "wobbegongshark",
  "wolf",
  "wolfspider",
  "wolverine",
  "wombat",
  "woodborer",
  "woodchuck",
  "woodcock",
  "woodnymphbutterfly",
  "woodpecker",
  "woodstorks",
  "woollybearcaterpillar",
  "worm",
  "wrasse",
  "wreckfish",
  "wren",
  "wrenchbird",
  "wryneck",
  "wuerhosaurus",
  "wyvern",
  "xanclomys",
  "xanthareel",
  "xantus",
  "xantusmurrelet",
  "xeme",
  "xenarthra",
  "xenoposeidon",
  "xenops",
  "xenopterygii",
  "xenopus",
  "xenotarsosaurus",
  "xenurine",
  "xenurusunicinctus",
  "xerus",
  "xiaosaurus",
  "xinjiangovenator",
  "xiphias",
  "xiphiasgladius",
  "xiphosuran",
  "xoloitzcuintli",
  "xoni",
  "xrayfish",
  "xraytetra",
  "xuanhanosaurus",
  "xuanhuaceratops",
  "xuanhuasaurus",
  "yaffle",
  "yak",
  "yapok",
  "yardant",
  "yearling",
  "yellowbelliedmarmot",
  "yellowbellylizard",
  "yellowhammer",
  "yellowjacket",
  "yellowlegs",
  "yellowthroat",
  "yellowwhitebutterfly",
  "yeti",
  "ynambu",
  "yorkshireterrier",
  "yosemitetoad",
  "yucker",
  "zander",
  "zanzibardaygecko",
  "zebra",
  "zebradove",
  "zebrafinch",
  "zebrafish",
  "zebralongwingbutterfly",
  "zebraswallowtailbutterfly",
  "zebratailedlizard",
  "zebu",
  "zenaida",
  "zeren",
  "zethusspinipes",
  "zethuswasp",
  "zigzagsalamander",
  "zonetailedpigeon",
  "zooplankton",
  "zopilote",
  "zorilla"
]
