import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import VerticalList from '../components/VerticalList';
import {getSequence} from '../store/sequencing/SequencingAction';

class HistoryCompare extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      classNames: 'icon icon__circle icon__scale-hover',
      toggle: true,
    };

    this.handleClick = this.handleClick.bind(this);
    this.getSequence = this.getSequence.bind(this);
  }

  static propTypes = {
    annotations: PropTypes.any,
    dispatch: PropTypes.func,
    history: PropTypes.any,
  };


  _renderComponents(data) {
    const {dispatch, annotations} = this.props;

    return data.map((item, index) => {
      if (item.events.length > 1) {
        const annotation = annotations.filter(entry => entry.id === item.id).reduce((acc, cur) => acc.concat(cur.annotatedEvents), []);
        return (
          <VerticalList
            key={index}
            data={item}
            annotation={annotation}
            toggle={this.state.toggle}
            dispatch={dispatch}
          />
        );
      }
    });
  }

  getSequence() {
    const {dispatch} = this.props;
    dispatch(getSequence());
  }

  handleClick(e) {
    this.setState(state => ({
      classNames: (!state.toggle) ? 'icon icon__circle icon__scale-hover' : 'icon icon__scale-hover icon__rotate icon__circle',
      toggle: !state.toggle,
    }));
  }

  render() {
    const {history} = this.props;


    return (
      <>
        <a href="/">
          <div className="icon icon__scale-hover icon__close"/>
        </a>
        <div
          onClick={() => this.getSequence()}
        >
          Interpret
        </div>
        <div className="canvas canvas__container">
          <div className="canvas__header">
            <div onClick={this.handleClick} className={this.state.classNames}/>
          </div>
          {
            this._renderComponents(history)
          }
        </div>

      </>
    );
  }
}

/**
 * map State to Props
 * @param {object} state
 * @return {{designs: *}}
 */
function mapStateToProps(state: Object) {
  return {
    history: state.history.history,
    annotations: state.history.annotations,
  };
}

export default connect(mapStateToProps)(HistoryCompare);
