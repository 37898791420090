

export const SORT_BYIV_START = 'SORT_BYIV_START';


export const sortByIv = (designId, Iv) => ({
  type: SORT_BYIV_START,
  designId,
  Iv
});
