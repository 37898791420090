import {SET_ITEM_DRAG, UPDATE_PLAYGROUND} from './PlaygroundFieldAction';
import {playGroundInitData} from './shape';


const initialState = {
  dragItem: '',
  data: playGroundInitData,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ITEM_DRAG:
      return {
        ...state,
        dragItem: action.dragItem,
      };
    case UPDATE_PLAYGROUND:
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
};
