import React from 'react';
import {TitleTexts} from '../constants/index';

export const insertText = (type) => {

  switch (type) {
    case TitleTexts.HELPTEXT.design:
      return (
        <>
          <p>A design holds one or multiple blocks. Please find a descriptive
            name for it.</p>
          <p><b>Participants:</b> the number of participants for whom a trial
            table will be generated.</p>
          <p><b>Inter trial time:</b> the inter trial time specifies the time
            a participant needs to advance from one trial to the next.</p>
          <p><b>Inter block time:</b> the inter block time specifies the time
            a participant needs to adance form one block to the next. Possible
            breaks should be added in here.</p>
          <p>Please find further help in the <a href='#'>design section</a> of
            the glossary.</p>
        </>
      );
    case TitleTexts.HELPTEXT.block:
      return (
        <>
          <p>A block holds one or multiple independant variables.</p>
          <p><b>Replications:</b> A group of rows is replicated and assigned
            to the same participant in order to decrease the variance / mean
            error with more data points. Changing the number of replications
            could make the counterbalancing invalid for Latin and Complete.
          </p>
          <p><b>Counterbalancing:</b></p>
          <ul>
            <li><i>Fixed Order:</i> each participant is exposed to a
              predefined order of levels.
            </li>
            <li><i>Latin square:</i> the order of levels are partially
              counterbalanced across a subset of participants using a Latin
              Square.
            </li>
            <li><i>Random:</i> Each participant is exposed to a randomised
              order of levels.
            </li>
            <li><i>Complete:</i> The order of levels are completely permuted
              across a subset of participants.
            </li>
          </ul>
          <p><b>Serial:</b> All blocks of the same type are now in consecutive
            order rather distributed.</p>
          <p>Please find further help in the <a href='#'>block section</a> of
            the glossary.</p>
        </>);
    case TitleTexts.HELPTEXT.independentVariable:
      return (
        <>
          <p>An independent variable holds at least two levels.</p>
          <p>The number of independent variables (IVs) has different dis-/
            advantages. Try to reduce the number of IVs, to just test your
            hypothesis. If your experiment is too complex, try to break down
            your hypothesis into two experiments.</p>
          <p><b>Advantage</b>: The more IVs, the more data you have to
            analyse.</p>
          <p><b>Disadvantage</b>: The more IVs, the harder the data analysis
            will be and the longer will the experiment be.</p>
        </>
      );
    case TitleTexts.HELPTEXT.level:
      return (
        <>
          <p>Increasing the number of levels has different dis-/
            advantages.</p>
          <p><b>Advantages</b>: The more levels an independent variable has
            the more data you have to analyse.</p>
          <p><b>Disadvantages</b>: Increasing the number of levels will make
            it more difficult to control their effect. The data analysis gets
            more difficult </p>
          <p>Try to keep the number of levels as low as possible.</p>
        </>
      );
    default:
      return (
        <>
          <p>A design holds one or multiple blocks. Please find a descriptive
            name for it.</p>
          <p><b>Participants:</b> the number of participants for whom a trial
            table will be generated.</p>
          <p><b>Inter trial time:</b> the inter trial time specifies the time
            a participant needs to advance from one trial to the next.</p>
          <p><b>Inter block time:</b> the inter block time specifies the time
            a participant needs to adance form one block to the next. Possible
            breaks should be added in here.</p>
          <p>Please find further help in the <a href='#'>design section</a> of
            the glossary.</p>
        </>
      );
  }
};
