import {all, fork} from 'redux-saga/effects';

import designsSaga from './designs/DesignsSaga';
import workspaceSaga from './workspace/WorkspaceSaga';
import powerSaga from './power/PowerSaga';
import playgroundFieldSaga from './playgroundField/PlaygroundFieldSaga';
import dataTransformationSaga
  from './dataTransformation/DataTransformationSaga';
import historySaga from './history/HistorySaga';
import appSaga from './app/AppSaga';
import sequenceSaga from './sequencing/SequencingSaga';
import errorSaga from './error/ErrorSaga';
import trialTableSaga from './trialTable/TrialTableSaga';

/**
 * rootSaga
 */
export default function* root() {
  yield all([
    fork(designsSaga),
    fork(powerSaga),
    fork(workspaceSaga),
    fork(playgroundFieldSaga),
    fork(dataTransformationSaga),
    fork(historySaga),
    fork(appSaga),
    fork(sequenceSaga),
    fork(errorSaga),
    fork(trialTableSaga),
  ]);
}
