import PropTypes from 'prop-types';
import React from 'react';
import Annotations from './Annotations';
import InputList from './InputList';


class VerticalEntry extends React.PureComponent {
  constructor(props) {
    super(props);
    this._onMouseDown = this._onMouseDown.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
  }

  state = {
    indicatorClass: 'v-list__indicator',
    visible: false,
  };

  static propTypes = {
    annotation: PropTypes.any,
    clickActive: PropTypes.bool,
    entry: PropTypes.object,
    id: PropTypes.string,
    onClickDown: PropTypes.func,
    onClickUp: PropTypes.func,
    update: PropTypes.func,
  };


  _onMouseDown(entry) {
    const {onClickDown, id} = this.props;
    this.setState({
      indicatorClass: 'v-list__indicator v-list__indicator--red',
    });
    onClickDown(entry, id);
  }

  _onMouseUp() {
    this.setState({
      visible: true,
      indicatorClass: 'v-list__indicator',
    });
  }

  _onSubmit() {
    this.setState({
      visible: false,
    });
  }

  _renderAnnotations() {
    const {annotation} = this.props;
    if (annotation.length > 0) {
      return annotation.map((item, index) => <Annotations key={item + index} annotation={item} />);
    }
  }

  _onMouseOver() {
    const {clickActive} = this.props;

    if (clickActive) {
      this.setState({
        indicatorClass: 'v-list__indicator v-list__indicator--red',
      });
    }
  }

  _renderIndicator() {
    const {entry, update} = this.props;
    const {indicatorClass} = this.state;
    return (
      <div className="v-list__center">
        {this._renderAnnotations()}
        <div
          className={indicatorClass}
          onMouseEnter={() => update(entry)}
          onMouseDown={() => this._onMouseDown(entry)}
          onMouseUp={() => this._onMouseUp(entry)}
          onMouseOver={() => this._onMouseOver(entry)}
        />
      </div>
    );
  }

  render() {
    const {entry, onClickUp, annotation} = this.props;
    const {visible} = this.state;
    const event = new Date(entry.date);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };

    return (
      <>
        <InputList
          className="v-entry__input"
          visible={visible}
          confirm={onClickUp}
          entry={entry}
          annotation={annotation}
          onSubmit={this._onSubmit}
        />
        {this._renderIndicator()}
        <div className="v-list__date">
          {event.toLocaleString('en-DE', options)}
        </div>
      </>
    );
  }
}

export default VerticalEntry;
