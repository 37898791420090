import Blockly from 'node-blockly/browser';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {updateCurrentWorkspace} from '../store/designs/DesignsAction';
import Button from './Button';
import Logo from './Logo';
import {triggerDownload} from './modules/TrialTable/TrialTableHelper';
import * as moment from 'moment';

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.designFileInputButton = React.createRef();
    this.workspaceFileInputButton = React.createRef();
  }

  /**
   * Processes one uploaded file
   * @param {event} event
   */
  importDesign(event) {
    const fileList = event.target.files;
    const {0: file} = fileList;
    const fileReader = new FileReader();
    fileReader.readAsText(file);
    fileReader.onload = () => {
      const xmlString = fileReader.result;
      const xmlBlock = Blockly.Xml.textToDom(xmlString);
      const {workspaceXml} = this.props;
      const workspace = Blockly.Xml.textToDom(workspaceXml);
      workspace.appendChild(xmlBlock);
      const workspaceString = Blockly.Xml.domToText(workspace);
      this.props.dispatch(updateCurrentWorkspace(workspaceString));
    };
  }

  clickFileUploadButton() {
    const current = this.designFileInputButton;
    current.click();
  }

  exportWorkspace() {
    const {workspaceXml} = this.props;
    const fileType = 'data:application/xml;charset=utf-8';

    const timestamp = moment()
      .format('YYMMDD HHmmss');
    const fileName = `workspace - ${timestamp}.xml`;

    triggerDownload(workspaceXml, fileType, fileName);
  }

  importWorkspace(event) {
    const fileList = event.target.files;
    const {0: file} = fileList;
    const fileReader = new FileReader();
    fileReader.readAsText(file);
    fileReader.onload = () => {
      const xmlString = fileReader.result;
      this.props.dispatch(updateCurrentWorkspace(xmlString));
    };
  }

  clickImportWorkspaceButton() {
    const current = this.workspaceFileInputButton;
    current.click();
  }

  render() {
    const {userid} = this.props;
    return (
      <header className="app__header">
        <Logo/>
        <div className="app__header__info">
          <div>
            <h1>Touchstone2 </h1>
            <p> Re-implementation of Touchstone2.
              Work-in-progress.</p>
          </div>
          <p>
            {userid}
          </p>
          {/* <Button text="Preregistration"/>*/}
          <div style={{clear: 'both'}}>
            <Button
              text="Import Workspace"
              onChange={() => this.clickImportWorkspaceButton()}
              additionalClassBackground={['button--float-right']}
            />
            <input
              hidden={true}
              ref={input => (this.workspaceFileInputButton = input)}
              onChange={(event) => this.importWorkspace(event)} type={'file'}
            />
            <Button
              text="Export Workspace"
              onChange={() => this.exportWorkspace()}
              additionalClassBackground={['button--float-right']}
            />
            <Button
              text="Import Design"
              onChange={() => this.clickFileUploadButton()}
              additionalClassBackground={['button--float-right']}
            />
            <input
              hidden={true} ref={input => (this.designFileInputButton = input)}
              onChange={(event) => this.importDesign(event)} type={'file'}
            />
          </div>
        </div>


      </header>

    );
  }
}

Header.propTypes = {
  dispatch: PropTypes.func,
  userid: PropTypes.string,
  workspaceXml: PropTypes.string,
};

/**
 * Map state to props
 * @param {object} state
 * @return {{trialtable: *}}
 */
function mapStateToProps(state) {
  return {
    workspaceXml: state.designs.workspaceXML,
    userid: state.history.id,
  };
}

export default connect(mapStateToProps)(Header);
